const dev = {
  FRONT_URL: "https://pms.kw-ref.com/#/",
  API_ENDPOINT_URL: "https://pms.kw-ref.com:7000/",
  // API_ENDPOINT_URL: "https://pms.kw-ref.com:3000/",
  // API_ENDPOINT_URL: 'http://localhost:8000/',
  // LP_API_ENDPOINT_URL: 'https:///rawdalsaleheen.edu.kw:8000',
  LP_API_ENDPOINT_URL: "http://localhost:4000",
};

const prod = {
  // FRONT_URL: 'https://pms.rawdalsaleheen.edu.kw/#/',
  // API_ENDPOINT_URL: 'https://pms.rawdalsaleheen.edu.kw:5000/',
  // LP_API_ENDPOINT_URL: 'https://rawdalsaleheen.edu.kw:8000',

  FRONT_URL: "https://pms.kw-ref.com/#/",
  API_ENDPOINT_URL: "https://pms.kw-ref.com:7000/",
  LP_API_ENDPOINT_URL: "https:///rawdalsaleheen.edu.kw:8000",
};

const staging = {
  // FRONT_URL: "https://pms.kw-ref.com/#/",
  // API_ENDPOINT_URL: "http://pms.kw-ref.com:7000",
  // LP_API_ENDPOINT_URL: 'https:///rawdalsaleheen.edu.kw:8000',
};

const local = {
  API_ENDPOINT_URL: "http://localhost:6600/",
  FRONT_URL: "http://localhost:3000/#/",
  LP_API_ENDPOINT_URL: "https:///rawdalsaleheen.edu.kw:8000",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "staging":
      return staging;
    case "local":
      return local;
    default:
      break;
  }
};

export const env = getEnv();
